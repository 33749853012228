<template>
  <div />
</template>

<script>
import 'animate.css'

export default {
  data() {
    return {
      currentTransaction: null,
      loaded: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getCurrentTicket()
      },
    },
    loaded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$swal({
            title: 'Transaction',
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            padding: '3em',
            didOpen: () => {
              this.$swal.showLoading()
            },
          })
        }
      },
    },
  },
  methods: {
    async getCurrentTicket() {
      this.loaded = true
      this.currentTransaction = await this
        .$http
        .get(`/find-all-by-ticket/${this.$route.params.ticket}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loaded = false

          this.$swal({
            text: 'Transaction echouée',
            title: `TICKET : ${res.data.data.ticket}`,
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            padding: '3em',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            confirmButtonText: 'Voir mon histoirique',
            showClass: {
              popup: 'animate__animated animate__tada',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push({ name: 'admin-history' })
            }
          })
          return res.data
        })
        .catch(() => {
          this.loaded = false
          return null
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.img-gif {
  width: 250px;
  height: 250px;
}
</style>
